export const navigation = [
    { to: 'team', name: 'Команда' },
    { to: 'projects', name: 'Проекты' },
    { to: 'services', name: 'Услуги' },
    { to: 'about', name: 'О Нас' },
    { to: 'cooperation', name: 'Сотрудничество' },
];

export const navigationMobile = [
    { to: 'home', name: 'Начало' },
    { to: 'team', name: 'Команда' },
    { to: 'projects', name: 'Проекты' },
    { to: 'services', name: 'Услуги' },
    { to: 'about', name: 'О Нас' },
    { to: 'cooperation', name: 'Сотрудничество' },
];

export const redirect = (url: string) => {
    if (typeof window !== undefined) {
        window.location.href = url;
    }
};
