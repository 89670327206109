import React from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';

import {Label} from '../../components/Svg';
import {navigation} from '../../utils/navigation';

import {
    MenuIcon,
    Nav,
    NavbarContainer,
    NavItem,
    NavLink,
    NavLogo,
    NavMenu
} from './index.style';

const Navbar = ({toggle}: { toggle: () => void }) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <IconContext.Provider value={{color: '#000'}}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to="home" onClick={toggleHome} href="home" aria-label="main_logo">
                            <Label />
                        </NavLogo>
                        <MenuIcon onClick={toggle}>
                            <FaBars />
                        </MenuIcon>
                        <NavMenu>
                            {navigation.map((el) => (
                                <NavItem key={el.to}>
                                    <NavLink
                                        href={el.to}
                                        to={el.to}
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        offset={-70}
                                    >
                                        {el.name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;
